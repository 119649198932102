import React, { isValidElement, ReactNode } from 'react';
import styled from 'styled-components';

const Text = styled.div<{ index: number }>`
  grid-column: 1 / -1;
  display: grid;
  grid-auto-rows: initial !important;
  grid-row: ${({ index }) => index};
  text-align: justify;
`;

type Props = {
  index: number;
  children: ReactNode;
};

export const InjectedSeoText = ({ index = 1, children }: Props) => {
  if (!isValidElement(children)) {
    return null;
  }
  const isEmptyChildren =
    isValidElement(children) && typeof children.type === 'function' && !(children.type as React.FC)(children.props);

  if (isEmptyChildren) {
    return null;
  }

  return <Text index={index}>{children}</Text>;
};
