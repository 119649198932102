import React, { ReactNode } from 'react';
import GridCards from './Grid/GridCards';
import { DetailedCards } from './Detailed/DetailedCards';
import { useSettingsSelector } from '../../redux/modules/helpers/useSelector';

type Props = {
  children: ReactNode;
  className?: string;
};

export const GridDetailedCardsWrapper = (props: Props) => {
  const view = useSettingsSelector('listView');

  const CardsComponent = view === 'detailed' ? DetailedCards : GridCards;
  return <CardsComponent className={props.className}>{props.children}</CardsComponent>;
};
