import React from 'react';
import { OnPageContentBlock, OnPageTextProps } from 'src/client/components/OnPageSeo/OnPageText/OnPageText';
import { Route, Switch } from 'react-router';

const maHomepage: OnPageContentBlock = {
  text1: <h2>Fantasy to Reality: How Erotic Art by MetArt Transforms Your Viewing Experience</h2>,
  text2: (
    <div>
      Erotic art is distinguished by its ability to engage your senses on multiple levels. It’s not just about what you
      see but how you feel and experience the engaging content. At the forefront of this transformative experience is
      MetArt, a platform celebrated for its exceptional approach to erotic art. MetArt is not just a collection of
      erotic female photos; it is a unique blend of artistic and erotic content that transforms the viewer’s experience
      from mere fantasy to a deeply personal and immersive reality.
    </div>
  ),
};

const maGalleriesPage: OnPageContentBlock = {
  text1: <h2>Maximizing Pleasure: How to Customize Your Erotic Pics Collection for Ultimate Enjoyment</h2>,
  text2: (
    <span>
      Creating a personalized collection of erotic pics is all about enhancing your viewing experience and maximizing
      pleasure. Whether you're exploring intimate desires or seeking visual enjoyment, enjoying a personalized
      collection that reflects your unique tastes is key. Here’s how to maximize your pleasure with a custom selection
      of erotic content.{' '}
    </span>
  ),
};

const maMoviesPage: OnPageContentBlock = {
  text1: <h2>Nude Video Gallery: Celebrating the Human Form Through Digital Art</h2>,
  text2: (
    <span>
      <span>
        Did you at any point in your life think about how a person’s body could be turned into a masterpiece? Let's
        discuss something unique – the nude video gallery.
      </span>
      <span>
        These galleries exquisitely represent the human body. They focus on art and celebrate being human. In contrast
        to other kinds of videos, these galleries do not shame nudity.
      </span>
      <span>
        These videos demonstrate that the human figure can be both artistic and powerful. We believe these galleries
        help us look at our bodies with new eyes. They can change our perception of beauty and art.
      </span>
    </span>
  ),
};

const maUpdatesPage: OnPageContentBlock = {
  text1: <h2>How Naked New Videos Are Changing the Perception of Beauty</h2>,
  text2: (
    <span>
      <span>
        Have you ever observed how naked new movies are altering our idea of what is beautiful? These videos feature
        genuine people in their natural state, with none of the artificial attractiveness or heavy editing seen in most
        other media. They revel in the unadorned human body - a nice change from the often unrealistic standards set by
        current beauty paradigms.
      </span>
      <span>
        These films help us value our bodies by showing us their different types. They teach us that beauty comes in
        many shapes and sizes, so let’s discuss these radical videos’ impact on our perception of beauty.
      </span>
    </span>
  ),
};

const maModelsPage: OnPageContentBlock = {
  text1: <h2>Secrets to the Iconic Status of Nude Models at MetArt</h2>,
  text2: (
    <span>
      MetArt has established itself as a premier destination for those who appreciate the artistry and allure of nude
      modeling. With a reputation for showcasing some of the most captivating and diverse nude models, MetArt offers an
      unparalleled viewing experience. But what exactly makes these nude models at MetArt Iconic? Join us as we reveal
      the secrets behind their captivating charm and discover what sets them apart as icons in the world of erotic art.
    </span>
  ),
};

export const OnPageTextContentMa = (props: OnPageTextProps) => {
  const { contentName } = props;

  return (
    <Switch>
      <Route exact path="/" render={() => maHomepage[contentName]} />;
      <Route exact path="/galleries" render={() => maGalleriesPage[contentName]} />;
      <Route exact path="/erotic-films" render={() => maMoviesPage[contentName]} />;
      <Route exact path="/updates" render={() => maUpdatesPage[contentName]} />;
      <Route exact path="/models" render={() => maModelsPage[contentName]} />;
    </Switch>
  );
};
