type GaEvent = {
  event: string;
  // custom_name_snake_case
  eventCategory?: string;
  // upon_which_the_event_is_fired
  eventAction?: string;
  // custom_label_added_to_the_event_like_a_path_name_or_other_id
  eventLabel?: string;
};
export const googleAnalyticsEvents: Record<string, GaEvent> = {
  standardEvent: { event: 'gaEvent', eventCategory: '', eventAction: '', eventLabel: '' },
  movieSlideShowEvent: { event: 'gaEvent', eventCategory: 'movie_slideshow', eventAction: '', eventLabel: '' },
  modelStatsClickEvent: { event: 'gaEvent', eventCategory: 'model_stats_click', eventAction: '', eventLabel: '' },
  tagsViewAllClickEvent: { event: 'gaEvent', eventCategory: 'tags_view_all_click', eventAction: '', eventLabel: '' },
  showShareDialog: { event: 'gaEvent', eventCategory: 'show_share_dialog', eventAction: '', eventLabel: '' },
  seoReadMore: { event: 'gaEvent', eventCategory: 'seo_read_more', eventAction: '', eventLabel: '' },
};
